import { login } from "api/auth/login"
import { signup } from "api/auth/signup"
import { updateUser } from "api/user/updateUser"
import { uploadAvatar } from "api/user/uploadAvatar"
import { verifyAccount } from "api/user/verifyAccount"
import useLocalStorage from "hooks/useLocalStorage"
import { createContext, useContext } from "react"
import { useMutation } from "@tanstack/react-query"
import ICustomer from "types/ICustomer"
import { checkExist } from "api/auth/checkExist"

interface Props {
    children: React.ReactNode
}

interface IDefaultState {
    token: string | any
    user: ICustomer | any
    logout: () => void
    login: any
    signup: any
    updateUser: any
    uploadAvatar: any
    verifyAccount: any
    checkExist: any
    resetSignupError: () => void
}

const initialValue = {
    token: "",
    user: {
        id: "",
        first_name: "",
        last_name: "",
        contact: {
            callingCode: "",
            phone: "",
            email: "",
        },
        image: "",
        callingCode: "",
        phone: "",
        street: "",
        province: "",
        nationality: "",
        country: "",
        city: "",
        gender: "",
        password: "",
        email: "",
        verification: {
            is_email_verified: false || true,
            is_phone_verified: false || true,
        },
    },
    login: () => {},
    logout: () => {},
    signup: () => {},
    updateUser: () => {},
    uploadAvatar: () => {},
    verifyAccount: () => {},
    checkExist: () => {},
    resetSignupError: () => {},
}

const UserContext = createContext<IDefaultState>(initialValue)

const UserProvider: React.FC<Props> = ({ children }) => {
    const [token, setToken] = useLocalStorage<any>("pass_ticket_token", null)
    const [user, setUser] = useLocalStorage<any>("pass_ticket_user", null)

    const loginMutate = useMutation({
        mutationFn: login,
        onSuccess: (successData: any) => {
            // Handling the errors objects =>
            if (successData.errors) {
                return successData.errors[0].split(`"`)[1]
            }

            if (successData.message === "signinSuccessful") {
                setToken(successData.payload.token)
                setUser(successData.payload.user)
                // document.cookie = `pass_ticket_token=${successData.payload.token}; path=/;`
            }
        },
        onError: (error: any) => {
            return error
        },
    })

    const loginCheckExist = useMutation({
        mutationFn: checkExist,
        onSuccess: (successData: any) => {
            // if (successData.errors) {
            //     return successData.errors[0].split(`"`)[1]
            // }
            // if (successData.message === "operationSuccessful") {
            //     setError("")
            // }
            return successData
        },
        onError: (error: any) => {
            return error
        },
    })

    const signupMutate = useMutation({
        mutationFn: signup,
        onSuccess: (data) => {
            if (data.message === "signupSuccessful") {
                setToken(data.payload.token)
                setUser(data.payload.user)
            }
            return data
        },
        onError: (error: any) => {
            return error
        },
    })

    const updateUserMutate = useMutation({
        mutationFn: updateUser,
        onSuccess: (data) => {
            // Pick some key and value from the payload tp store it in new user local storage
            if (updateUserMutate.error === null) {
                const picked = (({
                    first_name,
                    last_name,
                    contact,
                    image,
                    gender,
                    verification,
                }) => ({
                    first_name,
                    last_name,
                    contact,
                    image,
                    gender,
                    verification,
                }))(data.payload)

                setUser((prev: any) => ({
                    ...prev,
                    ...picked,
                }))
            }
        },
    })

    const uploadAvatarMutate = useMutation({
        mutationFn: uploadAvatar,
        onSuccess: (data) => {
            if (data.status !== 500) {
                if (data.message === "Success") {
                    const picked = (({ image }) => ({
                        image,
                    }))(data.payload)

                    setUser((prev: any) => ({
                        ...prev,
                        ...picked,
                    }))
                }
            } else {
                return data
            }
        },
    })

    const verifyAccountMutate = useMutation({
        mutationFn: verifyAccount,
        onSuccess: (data) => {
            if (data.message === "verified") {
                const picked = (({ verification }) => ({
                    verification,
                }))(data.payload)

                setUser((prev: any) => ({
                    ...prev,
                    ...picked,
                }))

                window.location.reload()
            }
        },
    })

    const resetSignupError = () => {
        signupMutate.reset()
    }

    const logout = () => {
        localStorage.removeItem("pass_ticket_token")
        localStorage.removeItem("pass_ticket_user")
        // document.cookie = "pass_ticket_token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;" // delete cookie
        location.href = "/"
    }

    return (
        <UserContext.Provider
            value={{
                user: user,
                token: token,
                logout: logout,
                signup: signupMutate,
                login: loginMutate,
                updateUser: updateUserMutate,
                uploadAvatar: uploadAvatarMutate,
                verifyAccount: verifyAccountMutate,
                checkExist: loginCheckExist,
                resetSignupError,
            }}>
            {children}
        </UserContext.Provider>
    )
}

// user context custom hook
export const useUserContext = () => {
    return useContext(UserContext)
}

export { UserContext, UserProvider }
