import { IPromotion } from "./event"

export interface ICart {
  eventId: string
  day: string
  month: string
  year: string
  ticketTypes: {
    ticketTypeTitle: string
    ticketTypePrice: number
    ticketTypeQuantity: number
    ticketTypeMin: number
    ticketTypeMax: number
    ticketSeatNumber: string
    // extras: [{}]
    // preJoinQuestions: [{}]
  }[]
}
export type eventStepsTypes =
  | "booking_policy_step"
  | "PrivacyPolicy"
  | "DaySelection"
  | "SelectTickets"
  | "SelectSeats"
  | "User"
  | "Summary"
  | "SpecialStep"
  | "AccreditationForm"

export interface ISelectedTicket {
  type_id: string
  timeslot_id: string
  start: Date
  end: Date
  ar_title: string
  en_title: string
  price: number
  limit: number
  min: number
  max: number
  color: string
  must_select_seat: boolean
  display_seat_map?: boolean
  group?: string
  promotion?: {
    get_qty?: number
    buy_qty?: number
    status: string
  }
}

export interface ISelectedSeat {
  id: string
  label: string
}

export interface IRequiredSelectedTickets {
  type_id: string
  timeslot_id: string
}
export interface ISeatWithoutLabel {
  seat_id: string
}

export interface IGuest {
  first_name: string
  last_name: string
  phone: string
  calling_code: string
}
export interface ITicketCoupon {
  coupon_id: string
}

// booking steps
export type BookingSteps =
  | "booking_policy_step"
  | "PrivacyPolicy"
  | "DaySelection"
  | "SelectTickets"
  | "SelectSeats"
  | "User"
  | "Summary"
  | "SpecialStep"
  | "AccreditationForm"
// cart
export interface ICoupon {
  _id: string
  limit: number
  amount: number
  status: string
  discount_type: 1 | 2 // 1 = Discount by percentage | 2 = Discount by amount
}
export interface ICartState {
  event: string
  total: number
  selectedTickets: ISelectedTicket[]
  selectedDays: IDay | null
  selectedSeats: ISelectedSeat[]
  // selectedQuestions: []
  finalCart: ICart[]
  guestData?: IGuest
  coupon?: ICoupon
  bookingStep: eventStepsTypes
  accreditation: null | Record<string, string>
}

// CartReducer Actions

export type ADD_EVENT_ACTION = {
  type: ImprovedCartActionsEnum.ADD_EVENT | "ADD_EVENT"
  payload: { event: string }
}

export type ADD_DAY_ACTION = {
  type: ImprovedCartActionsEnum.ADD_DAY | "ADD_DAY"
  payload: IDay
}

export type ADD_TICKET_ACTION = {
  type: ImprovedCartActionsEnum.ADD_TICKET | "ADD_TICKET"
  payload: ISelectedTicket
}
export type ADD_SEATS_ACTION = {
  type: ImprovedCartActionsEnum.ADD_SEATS | "ADD_SEATS"
  payload: ISelectedSeat
}
export type ADD_COUPON_ACTION = {
  type: ImprovedCartActionsEnum.ADD_COUPON | "ADD_COUPON"
  payload: ICoupon
}
export type SET_GUESTDATA_ACTION = {
  type: ImprovedCartActionsEnum.SET_GUEST_DATA | "SET_GUEST_DATA"
  payload: IGuest
}
export type SET_BOOKING_STEP_ACTION = {
  type: ImprovedCartActionsEnum.SET_BOOKING_STEP | "SET_BOOKING_STEP"
  payload: BookingSteps
}
export type REMOVE_DAY_ACTION = {
  type: ImprovedCartActionsEnum.REMOVE_DAY | "REMOVE_DAY"
}
export type REMOVE_COUPON_ACTION = {
  type: ImprovedCartActionsEnum.REMOVE_COUPON | "REMOVE_COUPON"
}
export type REMOVE_TICKETS_ACTION = {
  type: ImprovedCartActionsEnum.REMOVE_TICKETS | "REMOVE_TICKETS"
}
export type REMOVE_TICKET_ACTION = {
  type: ImprovedCartActionsEnum.REMOVE_TICKET | "REMOVE_TICKET"
  payload: { type_id: string }
}
export type REMOVE_SEAT_ACTION = {
  type: ImprovedCartActionsEnum.REMOVE_SEAT | "REMOVE_SEAT"
  payload: { id: string; label?: string }
}
export type REMOVE_SEATS_ACTION = {
  type: ImprovedCartActionsEnum.REMOVE_SEATS | "REMOVE_SEATS"
}
export type CLEAR_CART_ACTION = {
  type: ImprovedCartActionsEnum.CLEAR_CART | "CLEAR_CART"
}
export type SET_ACCREDITATION_DATA_ACTION = {
  type: ImprovedCartActionsEnum.SET_ACCREDITATION_DATA | "SET_ACCREDITATION_DATA"
  payload: any
}

// the new type for guvali new booking
export type ADD_ALL_SELECTED_TICKETS_ACTION = {
  type:
  | ImprovedCartActionsEnum.ADD_ALL_SELECTED_TICKETS
  | "ADD_ALL_SELECTED_TICKETS"
  payload: ISelectedTicket[]
}

export enum ImprovedCartActionsEnum {
  ADD_DAY = "ADD_DAY",
  ADD_EVENT = "ADD_EVENT",
  ADD_TICKET = "ADD_TICKET",
  REMOVE_DAY = "REMOVE_DAY",

  // finished above
  ADD_SEATS = "ADD_SEATS",
  ADD_COUPON = "ADD_COUPON",
  REMOVE_COUPON = "REMOVE_COUPON",
  REMOVE_TICKETS = "REMOVE_TICKETS",
  REMOVE_TICKET = "REMOVE_TICKET",
  REMOVE_SEAT = "REMOVE_SEAT",
  REMOVE_SEATS = "REMOVE_SEATS",
  CLEAR_CART = "CLEAR_CART",
  SET_GUEST_DATA = "SET_GUEST_DATA",
  SET_BOOKING_STEP = "SET_BOOKING_STEP",

  SET_ACCREDITATION_DATA = "SET_ACCREDITATION_DATA",

  // the new enum for guvali new booking
  ADD_ALL_SELECTED_TICKETS = "ADD_ALL_SELECTED_TICKETS",
}

export type IReducerActions =
  | ADD_DAY_ACTION
  | ADD_EVENT_ACTION
  | ADD_TICKET_ACTION
  | REMOVE_DAY_ACTION
  | ADD_SEATS_ACTION
  | ADD_COUPON_ACTION
  | REMOVE_COUPON_ACTION
  | REMOVE_TICKETS_ACTION
  | REMOVE_TICKET_ACTION
  | REMOVE_SEAT_ACTION
  | REMOVE_SEATS_ACTION
  | CLEAR_CART_ACTION
  | SET_GUESTDATA_ACTION
  | SET_BOOKING_STEP_ACTION
  | ADD_ALL_SELECTED_TICKETS_ACTION
  | SET_ACCREDITATION_DATA_ACTION

interface IDay {
  allow_using_coupon: boolean
  dayName: string
  dayNumber: number
  end: Date // make sure to create a date object out of the fetch event response
  start: Date
  monthName: string
  monthNum: number
  seats_season_id?: string
  timeslot_id: string
  types: IDayType[]
  yearNum: string
}
export interface IDayType {
  _id: string
  ar_description: string
  en_description: string
  en_title: string
  ar_title: string
  color: string
  discount: ITypeDiscount
  limit: number
  max: number
  min: number
  must_select_seat: boolean
  promotion?: IPromotion
  price: number
}
interface ITypeDiscount {
  current_value: number
  previous_value: number
}
