import { useReducer, Reducer, useContext, createContext } from "react"
import {
  ICartState,
  IReducerActions,
  ImprovedCartActionsEnum,
} from "types/improved-booking"

interface Props {
  children: React.ReactNode
}

export function serializeCartData(cartData) {
  return encodeURIComponent(JSON.stringify(cartData))
}

export function deserializeCartData(serializedCartData) {
  return JSON.parse(decodeURIComponent(serializedCartData))
}

const improvedInitialCartValues: ICartState = {
  event: "",
  total: 0,
  selectedTickets: [],
  selectedDays: null,
  selectedSeats: [],
  finalCart: [],
  coupon: undefined,
  guestData: undefined,
  bookingStep: "PrivacyPolicy",
  accreditation: null
}

// const updatedCartData = { ...cartContext, bookingStep: newBookingStep }

const CartReducer: Reducer<ICartState, IReducerActions> = (state, action) => {
  // let virtualState = {}

  switch (action.type) {
    case ImprovedCartActionsEnum.ADD_EVENT:
      return {
        ...state,
        event: action.payload.event,
      }
    case ImprovedCartActionsEnum.ADD_DAY:
      return {
        ...state,
        selectedDays: action.payload,
      }
    case ImprovedCartActionsEnum.ADD_TICKET:
      // virtualState = {
      //     ...virtualState,
      //     selectedTickets: [...state.selectedTickets, action.payload],
      // }

      // const serializedCart = serializeCartData(virtualState)
      // window.history.pushState({}, "", `?cart=${serializedCart}`)

      return {
        ...state,
        selectedTickets: [...state.selectedTickets, action.payload],
      }

    // the new dispatch for guvali new booking
    case ImprovedCartActionsEnum.ADD_ALL_SELECTED_TICKETS:
      return {
        ...state,
        selectedTickets: action.payload,
      }

    case ImprovedCartActionsEnum.REMOVE_DAY:
      return {
        ...state,
        selectedDays: null,
      }
    case ImprovedCartActionsEnum.ADD_SEATS:
      const check = state.selectedSeats.find(
        (seat: any) => seat.id === action.payload.id
      )

      if (check) {
        return {
          ...state,
          selectedSeats: state.selectedSeats.filter(
            (seat: any) => seat.id !== action.payload.id
          ),
        }
      } else {
        return {
          ...state,
          selectedSeats: [...state.selectedSeats, action.payload],
        }
      }
    case ImprovedCartActionsEnum.ADD_COUPON:
      const serializedCart = serializeCartData(state)
      window.history.pushState({}, "", `?c=${serializedCart}`)

      return {
        ...state,
        coupon: action.payload,
      }
    case ImprovedCartActionsEnum.REMOVE_COUPON:
      return {
        ...state,
        coupon: undefined,
      }
    case ImprovedCartActionsEnum.REMOVE_TICKETS:
      return {
        ...state,
        selectedTickets: [],
      }
    case ImprovedCartActionsEnum.REMOVE_TICKET:
      const id = state.selectedTickets.findIndex(
        (t) => t.type_id === action.payload.type_id
      )
      const newTickets = [...state.selectedTickets]
      newTickets.splice(id, 1)

      return {
        ...state,
        selectedTickets: newTickets,
      }
    case ImprovedCartActionsEnum.REMOVE_SEAT:
      return {
        ...state,
        selectedSeats: state.selectedSeats.filter(
          (seat: any) => seat.id !== action.payload.id
        ),
      }
    case ImprovedCartActionsEnum.REMOVE_SEATS:
      return {
        ...state,
        selectedSeats: [],
      }
    case ImprovedCartActionsEnum.CLEAR_CART:
      return { ...state, ...improvedInitialCartValues }
    case ImprovedCartActionsEnum.SET_GUEST_DATA:
      return {
        ...state,
        guestData: action.payload,
      }
    case ImprovedCartActionsEnum.SET_BOOKING_STEP:
      return {
        ...state,
        bookingStep: action.payload,
      }

    case ImprovedCartActionsEnum.SET_ACCREDITATION_DATA:
      return {
        ...state,
        accreditation: action.payload
      }

    default:
      return state
  }
}

export const CartDispatchContext = createContext<
  React.Dispatch<IReducerActions>
>((action: IReducerActions) => { })

export const CartContext = createContext<ICartState>(improvedInitialCartValues)

export const CartProvider: React.FC<Props> = ({ children }) => {
  const [state, dispatch] = useReducer(CartReducer, improvedInitialCartValues)
  return (
    <CartContext.Provider value={state}>
      <CartDispatchContext.Provider value={dispatch}>
        {children}
      </CartDispatchContext.Provider>
    </CartContext.Provider>
  )
}

export const useCartState = () => {
  const context = useContext(CartContext)
  if (context === undefined) {
    throw new Error("useCartState must be used within a CartProvider")
  }
  return context
}
export const useCartDispatch = () => {
  const context = useContext(CartDispatchContext)
  if (context === undefined) {
    throw new Error("useCartDispatch must be used within a CartProvider")
  }
  return context
}
