import React, { ReactElement } from "react"

interface IProps {
    className?: string
    children?: ReactElement
    isOpen?: boolean
    Close?: Function
    disableBackground?: boolean
}

export default function Modal({
    className = "",
    children,
    isOpen = false,
    disableBackground = false,
    Close = () => {},
}: IProps) {
    if (disableBackground && isOpen) {
        document.body.style.overflow = "hidden"
    } else if (disableBackground && !isOpen) {
        document.body.style.overflow = "unset"
    }
    return !isOpen ? (
        <></>
    ) : (
        <div
            className="overlay"
            onClick={(e) => {
                Close(e)
            }}>
            <div
                className={"Modal cursor-default " + className}
                onClick={(e) => e.stopPropagation()}>
                {children}
            </div>
        </div>
    )
}
