import React from "react"
import { useLang } from "hooks/useLang"
import Link from "next/link"

const HelpButton = () => {
    const { t, isAr } = useLang()

    const changePosition = () => {
        if (isAr) {
            return "left-0 origin-bottom-left rotate-90"
        } else {
            return "right-0 origin-bottom-right -rotate-90"
        }
    }

    return (
        <div
            className={`group fixed bottom-1/4 z-50 my-auto h-7 w-16 ${changePosition()}`}>
            <div className="absolute transition-all -translate-x-1/2 -top-2 left-1/2 opacity-70 group-hover:top-7 group-hover:opacity-0">
                <div className="items-center justify-center hidden w-8 h-8 font-bold text-white bg-blue-600 rounded-full shadow-md cursor-none dark:bg-blue-100 dark:text-blue-600 md:flex">
                    <span className={isAr ? "-rotate-90" : "rotate-90"}>?</span>
                </div>
            </div>
            <div className="absolute top-0 left-0 flex items-center justify-center w-full h-full transition-all duration-500 bg-blue-600 rounded-tl-md rounded-tr-md opacity-70 group-hover:top-0 group-hover:opacity-70 dark:bg-blue-100 md:top-7 md:opacity-0">
                <Link
                    href={"https://api.whatsapp.com/send?phone=966530053321"}
                    target="_blank"
                    className="flex items-center justify-center w-full h-full text-xs text-center text-white capitalize dark:text-blue-600"
                    rel="noopener noreferrer">
                    {t("help")}
                </Link>
            </div>
        </div>
    )
}

export default HelpButton
