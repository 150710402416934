import Image from 'next/image';
import React, { ReactNode, useEffect } from 'react';
import { LazyMotion, domAnimation, m } from 'framer-motion';
import dynamic from 'next/dynamic';
import { useUserContext } from 'context/UserContext';
import { useRouter } from 'next/router';

const NewNavbar = dynamic(() => import('./NewNavbar'), {
  ssr: false,
  loading: () => {
    return <nav className={`flex h-[70px] w-full items-center justify-between bg-white px-5 py-2 shadow-3xl dark:bg-transparent dark:shadow-none md:px-10 md:shadow-transparent`}></nav>;
  },
});

interface IProps {
  children: ReactNode;
}

const AuthLayout = ({ children }: IProps) => {
  const { token } = useUserContext();
  const router = useRouter();

  useEffect(() => {
    if (token) router.push('/');
  }, [token, router]);

  return (
    <div className="flex min-h-screen w-full">
      {/* Left side (The content) */}
      <div className="relative flex flex-[1.5] flex-col dark:bg-gradient-to-b dark:from-[#221852] dark:to-[#18113B]">
        <NewNavbar auth />
        <div className="flex h-full w-full items-center justify-center">
          <div className="mx-auto w-full max-w-[450px] p-5">
            <LazyMotion features={domAnimation}>
              <m.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.2 }}
              >
                {children}
              </m.div>
            </LazyMotion>
          </div>
        </div>
      </div>

      {/* Right side (The background image) */}
      <div className="sticky top-0 hidden h-screen flex-[1] flex-col bg-[#F9F7FE] dark:bg-[#18113B] md:flex">
        <div className="mx-auto flex w-full max-w-[530px] justify-end">
          <Image
            style={{
              objectFit: 'contain',
            }}
            src="/svg/v2/stars.svg"
            alt="stars"
            width={50}
            height={50}
          />
        </div>

        <div className="mx-auto flex w-full flex-1 flex-col items-center justify-center gap-8 p-5 text-center text-2xl font-bold xl:gap-10">
          <span className="text-[4vw]">تحــســبــهــــــا</span>
          <span className="text-[4vw]">خيــــــــــــــــــــــال</span>
        </div>

        <Image
          style={{
            objectFit: 'contain',
          }}
          src="/svg/v2/auth-side-image.png"
          alt="background-auth-img"
          priority
          width={500}
          height={300}
          className="max-h-[550px] w-full"
        />
      </div>
    </div>
  );
};

export default AuthLayout;
