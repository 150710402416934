import Loading from "components/icons/Loading"
import { twMerge } from "tailwind-merge"
import React from "react"
import Link from "next/link"
import { useRouter } from "next/router"

interface IProps {
    prefixIcon?: React.ReactNode
    text?: string | React.ReactNode
    className?: string
    children?: React.ReactNode
    suffixIcon?: React.ReactNode
    size?: "small" | "default" | "large" | "full" | "search" | "medium"
    theme?: "primary" | "alternate" | "outline" | "custom"
    loading?: boolean
    disabled?: boolean
    type?: "button" | "submit" | "reset"
    onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void
    id?: string
    href?: string
}

const Button = ({
    className = "",
    prefixIcon = null,
    text = "",
    children = null,
    suffixIcon = null,
    size = "default",
    theme = "primary",
    loading = false,
    disabled = false,
    type = "button",
    id = "",
    onClick = () => {},
    href = "",
}: IProps) => {
    const router = useRouter()

    const baseClasses = [
        "rounded-full",
        "transition-all",
        "duration-300",
        "border",
        "text-sm",
        "base:text-base",
        "flex",
        "items-center",
        "justify-center",
    ]

    const themeClasses = {
        primary:
            "bg-action-theme-400 hover:bg-action-theme-500 text-white border-transparent",
        alternate:
            "bg-action-theme-100 text-action-theme-400 border-action-theme-400 bg-opacity-20",
        outline: "bg-transparent text-action-theme-400 border-action-theme-400",
        custom: className,
    }

    const disabledClasses =
        "cursor-not-allowed bg-gray-disable pointer-events-none"

    const classNames = twMerge(
        ...baseClasses,
        themeClasses[theme],
        (disabled || loading) && disabledClasses,
        className
    )

    const content = (
        <>
            {prefixIcon}
            {loading ? <Loading /> : text || children}
            {suffixIcon}
        </>
    )

    const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
        if (href) {
            router.push(href)
        } else {
            onClick(e)
        }
    }

    if (href) {
        return (
            <Link
                href={href}
                className={classNames}
                id={id}>
                {content}
            </Link>
        )
    }

    return (
        <button
            type={type}
            disabled={disabled || loading}
            className={classNames}
            onClick={handleClick}
            id={id}>
            {content}
        </button>
    )
}

export default Button
