import axios, { AxiosError } from "axios"

export enum MessageType {
    SUCCESS = "SUCCESS",
    RATE_LIMIT_EXCEEDED = "RATE_LIMIT_EXCEEDED",
    ERROR = "ERROR",
}

export type OtpType = "email" | "phone" | "unknown"

interface OtpRequestParams {
    value: string
    inputType: OtpType
    callingCode?: string
}

interface OtpRequestResponse {
    success: boolean
    messageType: MessageType
    message: string
    data?: any
    error?: any
}

export const requestOtp = async ({
    value,
    inputType,
    callingCode,
}: OtpRequestParams): Promise<OtpRequestResponse> => {
    console.log(`Requesting OTP for ${inputType}`, value)
    try {
        const res = await axios.post(
            `${process.env.NEXT_PUBLIC_API_URL}users/request-otp`,
            inputType === "email"
                ? { email: value }
                : { callingCode, phone: value }
        )

        if (res.status === 200 && res.data.message === "operationSuccessful") {
            console.log("OTP request successful:", res)
            return {
                success: true,
                messageType: MessageType.SUCCESS,
                message: "OTP request successful",
                data: res.data.payload,
            }
        } else {
            return {
                success: false,
                messageType: MessageType.ERROR,
                message: "OTP request failed",
                error: res.data,
            }
        }
    } catch (err) {
        const axiosError = err as AxiosError
        console.log("Response status is: ", axiosError.response?.status)
        console.log("Axios error response: ", axiosError.response)

        if (axiosError.response && axiosError.response.status === 429) {
            return {
                success: false,
                messageType: MessageType.RATE_LIMIT_EXCEEDED,
                message: "Rate limit exceeded",
                error: axiosError.response.data,
            }
        } else {
            return {
                success: false,
                messageType: MessageType.ERROR,
                message: "Error requesting OTP",
                error: axiosError,
            }
        }
    }
}
