import { useEffect } from "react"

import * as gtag from "../../../lib/gtag"
import { useRouter } from "next/router"

export interface EventsIntegrationData {
    eventId: string
    pixelId: string
    eventName: string
    tiktokPixelId?: string
    snapPixelId?: string
}

interface Props {
    pixelId?: string
    pageView?: boolean
    children: React.ReactNode
    eventId?: string
    trackData?: (title: string, data?: any) => void
}

interface EventPixel {
    eventId: string
    pixelId: string
}

const PixelIntegrationComponent = ({
    pixelId,
    pageView,
    children,
    trackData,
    eventId,
}: Props) => {
    const router = useRouter()
    const currentPath = router.asPath

    const guvaliId = process.env.NEXT_PUBLIC_GUVALI_ID
    const guvaliPixel = process.env.NEXT_PUBLIC_GUVALI_FACEBOOK_PIXEL_KEY

    const mainPixel = process.env.NEXT_PUBLIC_PASSTICKETS_FACEBOOK_PIXEL_KEY

    const integrationsData: Array<EventsIntegrationData> = [
        { eventName: "guvali", eventId: guvaliId, pixelId: guvaliPixel },
        {
            eventName: "ghabga",
            eventId: process.env.NEXT_PUBLIC_GHABGA_EVENT_ID,
            pixelId: process.env.NEXT_PUBLIC_GHABGA_FACEBOOK_PIXEL_KEY,
            snapPixelId: process.env.NEXT_PUBLIC_GHABGA_SNAPCHAT_PIXEL_KEY,
            tiktokPixelId: process.env.NEXT_PUBLIC_GHABGA_TIKTOK_PIXEL_KEY,
        },
    ]

    const eventData = integrationsData.find((event) =>
        currentPath.includes(event.eventId)
    )

    useEffect(() => {
        import("react-facebook-pixel")
            .then((x) => x.default)
            .then((ReactPixel) => {
                ReactPixel.init(eventId ? eventData?.pixelId! : mainPixel)
                // if (pageView) {
                ReactPixel.pageView()
                // }
                if (currentPath.includes(guvaliId)) {
                    if (trackData) {
                        ReactPixel.track("ViewContent", {
                            content_name: "guvali",
                        })
                    }
                }
                const handleRouterChange = (url: string) => {
                    gtag.pageView(url)
                    ReactPixel.pageView()
                }
                router.events.on("routeChangeComplete", handleRouterChange)
            })
    }, [router.events])

    return <>{children}</>
}

export default PixelIntegrationComponent
